import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import FilePaperclips from '../../../../../../../assets/images/file_paperclips.svg';
import TrainualLogo from '../../../../../../../assets/images/trainual-purple.png';
import { useEditorAccount } from '../../../../../contexts/AccountEditorContext';
import initTranslations from '../../../../../lib/initTranslations';
import Icon from '../../../../design_system/display/icons/Icon';
import {
  MultilineTruncatedText,
  TruncatedText,
} from '../../../../design_system/Triage/TruncatedText';
import { deprecated, fontMd1 } from '../../../../styled/TypeSystem';
import { embedCardOptions } from '../../lib/embedCardOptions';
import { MEDIA_SIZE_TO_WIDTH, embedMediaSize } from '../../shared/constants/editor';
import { DragHandle } from '../../shared/styles';
import EmbedOptionsBar from '../EmbedOptionsBar/EmbedOptionsBar';
import { BaseThreeDotButtonWrapper } from '../EmbedOptionsBar/ThreeDotButton';

const t = initTranslations('editor.embed_card');

const CardWrapper = styled.div<{ cardWidth: number }>`
  position: relative;
  width: ${({ cardWidth }) => `${cardWidth}px`};
  height: 10.5rem;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderDisabled}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};

  &:hover ${BaseThreeDotButtonWrapper} {
    display: inline;
  }

  &:hover ${DragHandle} {
    display: block;
  }
`;

const LinkWrapper = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 25%;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) =>
    `${constants.borderRadiusLg} 0 0 ${constants.borderRadiusLg}`};
  overflow: hidden;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
`;

const ImageLogo = styled.img`
  object-fit: contain;
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  box-sizing: border-box;
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 75%;
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd2} ${constants.spacerMd3}`};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

const Header = styled.div`
  height: 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h4`
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${deprecated.fontMd2};
  ${TruncatedText({})};

  ${LinkWrapper}:hover & {
    text-decoration: underline;
  }
`;

const Description = styled.p`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${fontMd1};
  ${MultilineTruncatedText({})};
`;

const ContentText = styled.p`
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  margin-block: ${({ theme: { constants } }) => constants.spacerMd2};
  ${fontMd1};
  ${TruncatedText({})};

  :hover {
    color: ${({ theme: { vars } }) => vars.accentPrimaryHover};
  }
`;

type ContentTypes =
  | { contentType?: 'media'; description?: string; imgUrl?: string }
  | { contentType: 'file'; description?: never; imgUrl?: never };

export type Props = {
  size: embedMediaSize;
  contentUrl?: string;
  isEditable?: boolean;
  title?: string;
  canFrameView?: boolean;
  dataTestId?: string;
} & ContentTypes;

const EmbedCard = ({
  size,
  contentUrl,
  contentType = 'media',
  imgUrl,
  isEditable = true,
  title,
  description,
  canFrameView = false,
  dataTestId = 'embed-card',
}: Props) => {
  const width = MEDIA_SIZE_TO_WIDTH[size];

  const { account } = useEditorAccount();
  const { logoUrl: accountLogoUrl } = account;
  const formattedContentUrl = contentUrl && contentUrl.replace(/^https?:\/\//, '');

  let contentDescription = description;
  let contentText = formattedContentUrl;
  let logoUrl = accountLogoUrl || TrainualLogo;

  if (contentType === 'file') {
    contentDescription = t('file_description');
    contentText = title;
    logoUrl = FilePaperclips;
  }

  return (
    <CardWrapper cardWidth={width} className='embed-card' data-testid={dataTestId}>
      <LinkWrapper href={contentUrl} target='_blank'>
        <ContentWrapper>
          <ImageWrapper>
            {imgUrl ? <Image src={imgUrl} /> : <ImageLogo src={logoUrl} />}
          </ImageWrapper>
          <TextWrapper>
            <Header>
              <Title className='title'>{title}</Title>
            </Header>
            {contentDescription && (
              <Description className='description'>{contentDescription}</Description>
            )}
            <ContentText className='url'>{contentText}</ContentText>
          </TextWrapper>
        </ContentWrapper>
      </LinkWrapper>
      {isEditable && (
        <>
          <DragHandle data-drag-handle>
            <Icon name='grip-vertical' />
          </DragHandle>
          <EmbedOptionsBar
            WrapperComponent={BaseThreeDotButtonWrapper}
            items={embedCardOptions({ includeViewOption: canFrameView })}
            menuType='embed'
            triggerId={uuidv4()}
          />
        </>
      )}
    </CardWrapper>
  );
};

export default EmbedCard;

import { ColorTokens } from 'saguaro';

import { MessageKeys } from '../core/ChatLogMessage/AIBotMessage/types';

export enum RenderingStrategy {
  Closed = 'closed',
  Expanded = 'expanded',
  Reduced = 'reduced',
}

export interface GetFontLinearGradientProps {
  leftColor: string | ColorTokens;
  rightColor: string | ColorTokens;
}

export enum StepStatus {
  NotStarted = 'not_started',
  Active = 'active',
  Completed = 'completed',
}

export enum UserMessageKind {
  Positive = 'positive',
  Negative = 'negative',
  Other = 'other',
}

export enum ChatLogStatus {
  Active = 'active',
  Completed = 'completed',
}

export interface ChatLogPair {
  botMessage: string;
  userMessage: string;
  key: MessageKeys;
  status: ChatLogStatus;
}

interface BaseSceneConfig {
  delay?: number;
  prefillTextArea?: boolean;
}

interface SpeedSceneConfig extends BaseSceneConfig {
  speed?: number;
  totalTime?: never;
}

interface TotalTimeSceneConfig extends BaseSceneConfig {
  totalTime: number;
  speed?: never;
}

export type SceneConfig = {
  delay?: number;
  prefillTextArea?: boolean;
} & (SpeedSceneConfig | TotalTimeSceneConfig);

export enum StepKeys {
  BuildAccount = 'build_account',
  CompanyInfo = 'company_info',
  //TODO: Replace with real keys
  AddPeople = 'add_people',
  AddGroups = 'add_groups',
  AddContent = 'add_content',
}

export enum InterceptorSceneKey {
  Content = 'content',
  Groups = 'groups',
}

export enum AIBotCurrentScreen {
  Welcome = 'welcome',
  Session = 'session',
}

import { EditorContent } from '@tiptap/react';
import styled, { css } from 'styled-components';

import hexToRGBA from '../../../../lib/HexToRGBA';
import Tooltip from '../../../design_system/display/Tooltip/Tooltip';
import Link from '../../../design_system/Link';
import TextAreaField from '../../../design_system/Triage/fields/TextAreaField';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import Scrollbar from '../../../styled/Scrollbar';
import { deprecated, fontMd1, fontSm4, fontSm5 } from '../../../styled/TypeSystem';
import { EMBED_BAR_HEIGHT } from '../components/EmbedOptionsBar/EmbedOptionsBarItems/shared/constants';
import editorStyles from '../EditorStyles';

const OUTLINE_CONTENT_WIDTH = '20rem';
export const TOOLBAR_Z_INDEX = 50;
export const WRAPPER_Z_INDEX = 1000;

// Base page styles
export const EditorWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${WRAPPER_Z_INDEX};
  overflow: hidden;
`;

export const BaseContentWrapper = styled.div`
  width: calc(100% - ${OUTLINE_CONTENT_WIDTH});
`;

// DS Override: Changing style of the textarea to match the designs in Figma
export const StyledTextAreaField = styled(TextAreaField)`
  min-height: 15.875rem;
`;

export const LockedOverlay = styled.div`
  background-color: ${({ theme: { vars } }) => hexToRGBA(vars.foundationSurface1, 0.5)};
  height: 100%;
  width: 100%;
  right: 0;
  z-index: 10;
  position: absolute;
  cursor: not-allowed;
`;

export const ToolbarLockedOverlay = styled(LockedOverlay)`
  height: -webkit-fill-available;
`;

export const GoBackButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme: { constants } }) => constants.spacerMd2};
  right: ${({ theme: { constants } }) => constants.spacerLg1};
`;

export const infoTextStyles = css<{ textAlign: 'left' | 'right' | 'center' }>`
  width: 100%;
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ theme: { vars } }) => vars.textPlaceholder};
`;

export const TimeToRead = styled.div<{ textAlign: 'left' | 'right' | 'center' }>`
  ${infoTextStyles}
`;

export const LabelWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${({ theme: { vars } }) => vars.textDisabled};
  ${fontSm4};
`;

// OutlineContent (Sidenav) styles
export const OutlineContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${OUTLINE_CONTENT_WIDTH};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-right: ${({ theme: { vars } }) => vars.borderSurface1} solid
    ${({ theme: { constants } }) => constants.borderRadiusXs};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: ${TOOLBAR_Z_INDEX + 1}; /* needs to be higher than the editor toolbar z-index */
`;

export const Scrollable = styled.div`
  flex-grow: 1;
  overflow: hidden auto;
  ${Scrollbar};
`;

export const StepEmojiWrapper = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm1} ${constants.spacerSm1} 0 0`};
  ${fontMd1};
`;

export const Emoji = styled.div`
  ${fontSm4};
`;

const SharedLinkStyles = css<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${({ isActive, theme: { constants, vars } }) =>
    isActive &&
    css`
      background-color: ${vars.foundationHover};
      color: ${vars.textDefault};
      font-weight: ${constants.fontMedium};
    `}

  :hover {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

export const StepLink = styled.a<{ isActive: boolean; isEditable: boolean }>`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm2} ${constants.spacerSm2} ${constants.spacerSm2} ${constants.spacerMd3}`};
  min-height: ${({ theme: { constants } }) => constants.heightSm};
  cursor: ${({ isEditable }) => (isEditable ? 'move' : 'pointer')};
  ${SharedLinkStyles};
`;

export const ConsumptionStepLink = styled.a<{ isActive: boolean; forcedOrder: boolean }>`
  min-height: 2.75rem;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd3}`};
  cursor: ${({ forcedOrder }) => (forcedOrder ? 'not-allowed' : 'pointer')};
  ${SharedLinkStyles};
`;

export const StepLabel = styled.h4`
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd3}`};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};
  ${fontSm5};
`;

export const StepTitle = styled.p<{ isActive: boolean; isDisabled?: boolean }>`
  margin: 0;
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
  overflow-wrap: break-word;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  ${({ isActive, theme: { constants } }) =>
    isActive &&
    css`
      font-weight: ${constants.fontMedium};
    `}

  ${MultilineTruncatedText({})};
  ${fontSm5};
`;

// DS override - Prevents text from wrapping to two lines
export const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  margin: ${({ theme: { constants } }) => `${constants.spacerLg1} 0 ${constants.spacerSm3} 0`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  height: ${({ theme: { constants } }) => constants.heightXs};
  width: 1.5rem;
  cursor: pointer;
`;

export const CurriculumTitleLink = styled(Link)`
  display: flex;
  align-self: flex-start;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
  width: 100%;
`;

export const EditorBreadcrumbs = styled.div`
  width: 100%;
`;

export const TopicTitle = styled.h1`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  text-align: center;

  ${deprecated.fontMd2};
`;

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 ${constants.spacerMd2} 0`};
  padding: 0;
`;

export const TopicEmojiWrapper = styled(IconWrapper)`
  margin: ${({ theme: { constants } }) => `${constants.spacerMd3} 0 0 0`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  height: 3.5rem;
  width: 3.5rem;
  ${deprecated.fontMd4};
`;

//  Top Toolbar styles
export const SharedToolbarStyles = css`
  display: flex;
  position: fixed;
  width: inherit;
  z-index: ${TOOLBAR_Z_INDEX};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
`;

export const EditorInputContainer = styled.div`
  width: 100%;
`;

export const SharedFlyoutStyles = css`
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  #flyout-primary-button {
    min-width: 6rem;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10.063rem;
`;

export const ImageContainer = styled.div`
  overflow-y: scroll;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  height: 15rem;
  ${Scrollbar};
`;

export const ImageColumns = styled.div`
  column-count: 2;
  column-gap: 0;
`;

export const EmbedLinkStyles = css`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
`;

export const AnimatedIconContainer = styled.div`
  height: ${({ theme: { constants } }) => constants.height2xs};
  width: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  z-index: 2;
  bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  right: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
  opacity: 0;
  background-image: radial-gradient(
    circle,
    transparent 40%,
    ${({ theme: { vars } }) => vars.foundationSurface1} 50%
  );
  background-size: 300% 350%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-position 1s ease-in-out, color 1s ease-in-out;

  .selected & {
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    opacity: 1;
    color: ${({ theme: { vars } }) => vars.borderHover};
    background-position: 0 0;
  }
`;

export const AnimatedGradient = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    transition: opacity 1s ease;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(85, 85, 85, 0) 55.73%, rgba(57, 57, 57, 0.8) 100%);
    z-index: 1;
    transform: translateY(100%);
    transition: transform 1s ease;
  }

  &:hover:before,
  &.selected:before {
    background: linear-gradient(
      180deg,
      rgba(85, 85, 85, 0) 55.73%,
      ${(props) => hexToRGBA(props.theme.vars.accentPrimaryDefault, 1)}
    );
    transform: translateY(0);
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    clip-path: polygon(100% 100%, 100% 0, 100% 50%, 100% 100%);
    transition: clip-path 1s ease;
  }

  &:hover img,
  &.selected img {
    opacity: 0.5;
  }

  &.selected:after {
    background: linear-gradient(180deg, rgba(85, 85, 85, 0) 55.73%, rgba(85, 85, 85, 0.8) 100%);
  }

  &:not(.selected):hover ${AnimatedIconContainer} {
    opacity: 1;
    transition: background-position 1s ease-in-out, color 1s ease-in-out;
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.foundationSurface1}`};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
    color: ${({ theme: { vars } }) => vars.foundationSurface1};
  }
`;

const excludeElements = [
  'p',
  'ul',
  'ol',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'pre',
  'code',
  'dl',
  'dt',
  'dd',
  'figcaption',
  'q',
  'cite',
  'abbr',
  'address',
  'time',
  's',
  'small',
  'strong',
  'em',
  'b',
  'i',
  'u',
  'mark',
  'span',
];
const notSelector = excludeElements.map((el) => `:not(${el})`).join('');

// Editor Content styles
export const StyledEditorContent = styled(EditorContent)`
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  a {
    word-wrap: anywhere;
  }

  .ProseMirror {
    height: 100%;
    padding: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 ${constants.spacerLg2} 0`};

    > *${notSelector} + *${notSelector} {
      margin-top: 1.5rem;
    }

    &:focus {
      outline: none;
    }

    p.is-editor-empty:first-child::before {
      color: ${({ theme: { vars } }) => vars.textPlaceholder};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    // See tiptap_extensions/paragraph/index.tsx addProseMirrorPlugins() for more details
    .empty-trailingbreaks {
      display: none;
    }

    .ProseMirror-gapcursor:after {
      top: -25px;
      height: 20px;
      border-left: ${({ theme: { constants } }) => constants.borderWidthSm} solid black;
      border-top: none;
    }

    &.resize-cursor {
      cursor: col-resize;
    }

    .column-resize-handle {
      background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 3px;
    }
  }

  .collaboration-cursor__caret {
    border: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.foundationSurface2}`};
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    position: absolute;
    left: 0.125rem;
    top: -2.5rem;
    user-select: none;
    white-space: nowrap;
    font-style: normal;
    font-family: Poppins, sans-serif;
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
    color: ${({ theme: { vars } }) => vars.textSurface};
    font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
    padding: ${({ theme: { constants } }) => constants.spacerSm3};
    ${fontSm5};
  }
  .tableWrapper {
    overflow-x: auto;
  }

  table {
    border-collapse: collapse;
    margin: ${({ theme: { constants } }) => `${constants.spacerMd2} 0 0 ${constants.spacerMd2}`};
    width: calc(100% - 2rem);
    table-layout: fixed;
    max-width: 100%;

    .grip-column {
      border-left: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
      height: 0.75rem;
      left: 0;
      margin-left: -1px;
      top: -0.75rem;
      width: calc(100% + 1px);
    }

    .grip-column.first,
    .grip-row.first {
      border-color: transparent;
      border-top-left-radius: ${({ theme: { constants } }) => constants.borderWidth2xl};
    }
    .grip-column.last {
      border-top-right-radius: ${({ theme: { constants } }) => constants.borderWidth2xl};
    }
    .grip-row.last {
      border-bottom-left-radius: ${({ theme: { constants } }) => constants.borderWidth2xl};
    }

    .grip-row {
      border-top: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
      height: calc(100% + 1px);
      left: -0.75rem;
      margin-top: -1px;
      top: 0;
      width: 0.75rem;
    }

    .grip-column,
    .grip-row {
      align-items: center;
      background-color: ${({ theme: { vars } }) => vars.foundationBase2};
      cursor: pointer;
      display: flex;
      justify-content: center;
      position: absolute;

      &:hover {
        background-color: ${({ theme: { vars } }) => vars.borderSurface2};
      }
    }

    .grip-column.selected,
    .grip-row.selected {
      /* Intentionally not using DS vars here - gives us a darker gray for the selected column/row */
      background: rgba(39, 42, 47, 0.4);
      border-color: rgba(39, 42, 47, 0.4);
    }

    .grip-column::before,
    .grip-row::before {
      content: '';
    }

    .grip-column::before {
      width: 0.625rem;
    }

    .grip-row::before {
      height: 0.625rem;
    }

    .grip-column.selected::before {
      border-bottom: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthLg} dotted ${vars.foundationSurface1}`};
    }

    .grip-column:hover::before {
      border-bottom: ${({ theme }) =>
        `${theme.constants.borderWidthLg} dotted rgba(39, 42, 47, 0.6)`};
    }

    .grip-row.selected::before {
      border-left: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthLg} dotted ${vars.foundationSurface1}`};
    }

    .grip-row:hover::before {
      border-left: ${({ theme }) =>
        `${theme.constants.borderWidthLg} dotted rgba(39, 42, 47, 0.6)`};
    }

    .selectedCell {
      background-color: ${({ theme: { vars } }) => vars.foundationHover};
      border-color: ${({ theme: { vars } }) => vars.borderSurface2};
      border-style: double;
    }

    .selectedCell:after {
      background: rgba(128, 128, 128, 0.35);
      content: '';
      inset: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    td,
    th {
      border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
        ${({ theme: { vars } }) => vars.borderSurface2};
      padding: ${({ theme: { constants } }) => constants.spacerSm3};
      min-width: 6.25rem;
      position: relative;
      text-align: left;
      vertical-align: top;

      p {
        margin-bottom: 0;
      }
    }
  }

  hr {
    /* border-top uses brand-styles */
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  [data-type='horizontalRule'] {
    cursor: pointer;
    height: ${({ theme: { constants } }) => constants.heightLg};
    border: ${({ theme: { constants } }) => `${constants.borderWidthLg} solid transparent`};
    border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

    align-items: center;
    display: flex;
    justify-content: center;

    &.ProseMirror-selectednode {
      background-color: ${({ theme: { vars } }) => vars.foundationBase2};
      border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
    }

    &:hover:not(&.ProseMirror-selectednode) {
      background-color: ${({ theme: { vars } }) => vars.foundationBase2};
    }

    hr {
      width: 97%;
    }
  }

  ${editorStyles};
`;

/**
 * TLDR; Gray area around the StepContent and TopicHeader
 *
 *  1. Defines the height of the editor content area based on the height of the toolbar
 *  2. Sets the position of the elements within the editor content area
 *  3. Allows scrolling in the editor content area
 *
 *  Additional notes:
 *
 *  Designs: https://www.figma.com/design/enh68X0H8nbA2OB7CEMGGQ/Trainual-Application?node-id=1-12&m=dev
 */
export const EditorContentWrapper = styled.div<{ toolbarAdjustment: string }>`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
  height: ${({ toolbarAdjustment }) => `calc(100% - ${toolbarAdjustment})`};
  margin-top: ${({ toolbarAdjustment }) => toolbarAdjustment};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};

  ${Scrollbar};
  /* override the border of the thumb to match the background color of the editor */
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: ${({ theme: { vars } }) => `${vars.borderSurface2} ${vars.foundationBase1}`};
  }
  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar-thumb {
      border-color: ${({ theme: { vars } }) => vars.foundationBase1};
    }
  }
`;

export const CardBackgroundStyle = css<{ isFlatTop?: boolean }>`
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd3} ${constants.spacerLg2} ${constants.spacerMd2} ${constants.spacerLg2}`};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants }, isFlatTop }) =>
    isFlatTop
      ? `0 0 ${constants.borderRadiusXl} ${constants.borderRadiusXl}`
      : `${constants.borderRadiusXl}}`};
`;

export const CardBackgroundShadowed = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  ${CardBackgroundStyle};
`;

/**
 * White container that holds the contents of the step within the editor
 */
export const StepContentBody = styled.div<{ isLastStep: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: ${({ theme: { constants }, isLastStep }) =>
    `0 0 ${isLastStep ? constants.borderRadiusXl : '0'} ${
      isLastStep ? constants.borderRadiusXl : '0'
    }`};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerMd3} ${constants.spacerLg2} ${constants.spacerLg1} ${constants.spacerLg2}`};
`;

/**
 * TLDR; Reusable container for keeping elements together in the step edit and consumption pages,
 * while still allowing the container to fill the remaining space of the parent container
 *
 * Additional notes:
 * 1. flex-grow: 1 allows the container to fill the remaining space in the parent container
 * allowing the buttons to stay at the bottom of the page even when the content is not long enough/empty
 * https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
 *
 * 2. hasPaddingBottom adds 40px padding to the bottom of the container used when on the last step of a topic
 *
 * 2. Designs: https://www.figma.com/design/enh68X0H8nbA2OB7CEMGGQ/Trainual-Application?node-id=3038-12321&m=dev
 */
export const ContentBlock = styled.div<{ hasPaddingBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ hasPaddingBottom, theme: { constants } }) =>
    hasPaddingBottom && `padding-bottom: ${constants.spacerLg2}`};
`;

/**
 * TLDR; Sets the width and spacing of the TopicHeader and StepContent
 *
 *  1. Defines the width of the editor content area based on the screen size
 *  2. Adds padding to the top and sides of the editor content area
 *
 *  Additional notes:
 *
 *  The difference between the left and right padding is to account for the scrollbar
 *
 *  Designs: https://www.figma.com/design/enh68X0H8nbA2OB7CEMGGQ/Trainual-Application?node-id=1-12&m=dev
 */
export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  min-width: 40rem;
  width: 53.5rem;
  min-height: 100%;
  padding: ${({ theme: { constants } }) => `${constants.spacerLg2} 4rem 0 5rem`};

  @media (min-width: ${mediaBreakpointPxXl}) {
    padding: ${({ theme: { constants } }) => `${constants.spacerLg2} 0 0 ${constants.spacerMd2}`};
  }
`;

const SharedHrStyles = css`
  background-color: ${({ theme: { vars } }) => vars.borderSurface2};
  border: none;
  height: 1px;
`;

export const StyledHrEdit = styled.hr`
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  ${SharedHrStyles};
`;

export const StyledHr = styled.hr`
  ${SharedHrStyles};
`;

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const NavigationButtonWrapper = styled.div`
  width: 50%;
`;

export const HeadingWrapper = styled.div`
  margin: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  display: flex;
  flex-direction: column;
`;

export const DragHandle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd2}`};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  border-radius: 0 0 ${({ theme: { constants } }) => constants.borderRadiusMd} 0;
  display: none;
  cursor: pointer;
`;

export const BubbleFlyout = styled.div`
  position: absolute;
  top: calc(${EMBED_BAR_HEIGHT} - 0.15rem);
  transform: translateX(55%);
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  width: 50%;
`;

export const BubbleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

export const BubbleForm = styled.form`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CustomNodeSelectedState = styled.div<{ selected: boolean; stretchToFit?: boolean }>`
  ${({ stretchToFit }) =>
    stretchToFit &&
    css`
      display: flex;
      align-items: stretch;
    `}

  ${({ selected }) =>
    selected &&
    css`
      outline: ${({ theme: { constants } }) => constants.borderWidthLg} solid
        ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
    `}
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
